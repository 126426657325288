import TableLayout from "../TableLayout/TableLayout";
import avatar from "../../images/default_avatar/1.png";
import Avatar from "../layout/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPhone,
    faTimes,
    faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { formatPhoneOrExt } from "../../utils/formatting";

function UserContactsTable({ data, onDeleteContact, onEditEvent, onCall }) {
    console.log('DATA ON COMPONENT', data);
    const headers = [
        { title: "", className: "w-24" },
        "Nome",
        "Telefone",
        "E-mail",
        "Empresa",
        "Setor",
        { title: "", className: "w-16 2xl:w-32" },
    ];

    const rowTrasform = (item) => {
        return [
            <Avatar
                avatar={avatar}
                className={`transition-all duration-500 w-8 h-8 2xl:w-12 2xl:h-12 bg-gray-bluish`}
            />,
            <span className="font-bold">{item.name}</span>,
            formatPhoneOrExt(item.phone),
            item.email,
            item.company,
            item.sector,
            <div className="flex">
                {item.editable ? (
                    <div
                        className="cursor-pointer text-gray-dark mr-2 2xl:mr-8 text-lg w-7"
                        onClick={() => onEditEvent && onEditEvent(item)}
                    >
                        <FontAwesomeIcon
                            icon={faPencilAlt}
                            className="text-lg 2xl:text-2xl"
                        />
                    </div>
                ) : (
                    <div className="mr-8 w-7"></div>
                )}
                <div
                    className="cursor-pointer text-green mr-2 2xl:mr-8 text-lg w-7"
                    onClick={() => onCall && onCall(item.phone, item.name)}
                >
                    <FontAwesomeIcon
                        icon={faPhone}
                        className="text-lg 2xl:text-2xl"
                    />
                </div>
                {item.removable ? (
                    <div
                        className="cursor-pointer text-red text-lg w-7"
                        onClick={() =>
                            onDeleteContact && onDeleteContact(item.id)
                        }
                    >
                        <FontAwesomeIcon
                            icon={faTimes}
                            className="text-lg 2xl:text-2xl"
                        />
                    </div>
                ) : (
                    <div className="w-7"></div>
                )}
            </div>,
        ];
    };

    return (
        <TableLayout data={data} rowTransform={rowTrasform} headers={headers} />
    );
}

export default UserContactsTable;
