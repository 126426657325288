import { useEffect, useState } from "react";
import ContentWrapper from "../components/layout/ContentWrapper";
import PageLoadSpinner from "../components/layout/PageLoadSpinner";
import FadeInOut from "../components/layout/FadeInOut";
import { SetupExtensionsPage } from "./SetupWizard/SetupExtensions.page";
import { Step, Stepper } from "../components/layout/Stepper";
import {
  SetupWizardAccountMetadata,
  SetupWizardConfig, saveSetupWizardConfig,
} from "../model/setupWizard.model";
import { useAuth } from "../services/firebase/auth";
import { SetupStartPage } from "./SetupWizard/SetupStart.page";
import { SetupQueuesPage } from "./SetupWizard/SetupQueues.page";
import { SetupIvrPage } from "./SetupWizard/SetupIvr.page";
import { toast } from "react-toastify";
import { getWizardAccountMetadata, getWizardInitConfig } from "../services/setupWizard";
import { SetupConfirmationPage } from "./SetupWizard/SetupConfirmation.page";
import { SetupAlreadyFinalizedPage } from "./SetupWizard/SetupAlreadyFinalized.page";
import Logo from "../components/layout/Logo";
import { saveSetupWizard } from "../services/setupWizard.service";
import { SetupError } from "./SetupWizard/SetupError.page";
import { SetupSuccess } from "./SetupWizard/SetupSuccess.page";

export function SetupWizardPage() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState(false);
  const [hasFinished, setHasFinished] = useState(false);

  const [page, setPage] = useState<number>(0);
  const [config, setConfig] = useState<SetupWizardConfig>({});
  const [account, setAccount] = useState<SetupWizardAccountMetadata>();

  const auth = useAuth();

  const steps: Step[] = [
    {
      name: "Início",
    },
    {
      name: "Ramais",
    },
    {
      name: "Atendimento Automático",
    },
    {
      name: "Filas",
    },
    {
      name: "Finalização",
    },
  ];

  const load = async () => {
    try {
      const companyId = auth.user.token.claims.company;
      const data = await getWizardInitConfig(companyId);
      const account = await getWizardAccountMetadata(companyId)
      setConfig(data);
      setAccount(account);
    } catch (e) {
      toast("Conta não encontrada", {type: "error"})
    }
  }

  const nextPage = () => {
    setPage((p) => p + 1)
  }

  const backPage = () => {
    setPage((p) => p - 1)
  }

  const save = async () => {
    setIsLoading(true);
    const companyId = auth.user.token.claims.company;
    const result = await saveSetupWizard(companyId, config);
    if(result.error) {
      setHasError(true);
    }
    setHasFinished(true);
    setIsLoading(false);
  }

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    console.log(config);
  }, [config]);

  return (
    <div className="bg-white-dark min-h-screen flex flex-col items-center py-20">
      <PageLoadSpinner show={isLoading} />
      <ContentWrapper show={!isLoading} className="w-full max-w-[950px] px-8">
        <Logo type="dark" className="mb-12" />
        {account?.finalizedSetupWizard && (
          <SetupAlreadyFinalizedPage />
        )}
        {hasFinished && hasError && (
          <SetupError />
        )}
        {hasFinished && !hasError && (
          <SetupSuccess />
        )}
        {config && !hasFinished && !account?.finalizedSetupWizard && (
          <>
            <div className="w-full">
              <Stepper steps={steps} current={page} className="mt-10 mb-20" />
              <FadeInOut
                selector={() => {
                  return page;
                }}
                options={[
                  <SetupStartPage next={nextPage} back={backPage}  />,
                  <SetupExtensionsPage value={config} setter={setConfig} account={account!} next={nextPage} back={backPage} />,
                  <SetupIvrPage value={config} setter={setConfig} next={nextPage} back={backPage} />,
                  <SetupQueuesPage value={config} setter={setConfig} next={nextPage} back={backPage} />,
                  <SetupConfirmationPage value={config} next={save} back={backPage} />
                ]}
                className="mb-4"
              />
            </div>
          </>
        )}
      </ContentWrapper>
    </div>
  );
}
