import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import CardTitle from "../layout/CardTitle";
import Select from "../layout/Select";
import { useAuth } from "../../services/firebase/auth";
import { getCompanyData } from "../../services/company";
import { buildPredefinedMessages } from "../ivrs/PredefinedMessages";
import { toast } from "react-toastify";
import { PredefinedMessageSelection } from "./PredefinedMessageSelection";
import { TextMessageSelection } from "./TextMessageSelection";
import { FileMessageSelection } from "./FileMessageSelection";
import { AudioMessage, MessageTypes } from "../../model/queue.model";
import { Setter } from "../../utils/types";

export function AudioSelector(props: AudioSelectorProps) {
  const [predefinedMessages, setPredefinedMessages] = useState<string[]>();
  const auth = useAuth();

  const onSelectType = (value: MessageTypes) => {
    if (!predefinedMessages) return;

    switch (value) {
      case "file":
        props.setter({
          id: props.id,
          type: value,
          filename: "",
          formData: new FormData(),
          hasChanged: true,
          useOptions: props.useOptions || false,
        });
        break;
      case "predefined":
        props.setter({
          id: props.id,
          type: value,
          predefined: 0,
          text: predefinedMessages![0],
          useOptions: props.useOptions || false,
        });
        break;
      case "text":
        props.setter({
          id: props.id,
          type: value,
          text: "",
          useOptions: props.useOptions,
        });
        break;
      case "none":
        props.setter({
          id: props.id,
          type: "none",
        });
        break;
      default:
        props.setter({
          id: "",
          type: value,
          text: "",
          useOptions: false,
      });
    }
  };

  const loadPredefinedMessages = useCallback(async () => {
    const company = await getCompanyData(auth);
    if (!company) {
      toast("Erro ao obter dados da empresa");
      return;
    }

    const { name, fantasyName } = company;
    const message = fantasyName ? fantasyName : name;

    setPredefinedMessages(buildPredefinedMessages(props.id, message));
  }, []);

  useEffect(() => {
    // Load predefined messages before anything else
    loadPredefinedMessages();
  }, []);

  return (
    <div className={props.className}>
      {props.title && <CardTitle>{props.title}</CardTitle>}
      {props.description && <div>{props.description}</div>}
      <div className="mb-4">
        <Select
          title="Tipo de mensagem"
          className="w-72"
          value={props.value?.type}
          setter={onSelectType}
          //@ts-ignore
          onChange={() => props.onChange}
          formValidation={props.formValidation}
          tooltipMessageId="ivr-message-type"
          required={props.required}
          emptyOption={false}
        >
          <option value="none">Sem mensagem de áudio</option>
          <option value="predefined">Pré-definida</option>
          <option value="text">Texto</option>
          <option value="file">Upload de áudio</option>
        </Select>
      </div>
      <div className="mb-4">
        {props.value?.type === "predefined" && (
          <PredefinedMessageSelection
            message={props.value}
            setMessage={props.setter}
            options={predefinedMessages || []}
            formValidation={props.formValidation}
            onChange={props.onChange}
          />
        )}
        {props.value?.type === "text" && (
          <>
            <TextMessageSelection
              message={props.value}
              setMessage={props.setter}
              formValidation={props.formValidation}
              onChange={props.onChange}
            />
          </>
        )}
        {props.value?.type === "file" && (
          <FileMessageSelection
            message={props.value}
            setMessage={props.setter}
            formValidation={props.formValidation}
            onChange={props.onChange}
          />
        )}
        {props.value?.type === "none" && (
          <></>
        )}
      </div>
    </div>
  );
}

export type AudioSelectorProps = {
  id: string;
  value: AudioMessage;
  setter: Setter<AudioMessage>;
  useOptions?: boolean;
  title: string;
  description: React.ReactNode;
  required?: boolean;
  onChange?: () => void;
  formValidation?: any;
  className?: string;
};
