import TableLayout from "../TableLayout/TableLayout";
import { formatISODateTime } from "../../utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserPlus,
    faPhone,
    faLocationArrow,
    faVoicemail,
} from "@fortawesome/free-solid-svg-icons";

function ReportTable({
    data,
    extensions,
    hasLoaded,
    addContact,
    initiateCall,
    downloadRecording,
    queues,
    contacts
}) {
    console.log('>>>>>> ReportsTable data', data);
    const headers = [
        { title: "", className: "w-10" },
        "Nome",
        "Telefone",
        "Atendente",
        "Fila",
        "Data",
        "Duração",
        { title: "Status", className: "w-32" },
        { title: "", className: "w-32" },
    ];

    const statusMissed = (
        <div className="py-1.5 px-3 2xl:px-6 2xl:py-2 border-red border-2 text-red font-bold rounded-15 text-center">
            Perdido
        </div>
    );
    const statusAnswered = (
        <div className="py-1.5 px-3 2xl:px-6 2xl:py-2 border-green border-2 text-green font-bold rounded-15 text-center">
            Atendido
        </div>
    );

    const inbound = (item) => {
        return (
            <FontAwesomeIcon
                icon={faLocationArrow}
                rotation={180}
                className={`${
                    item.status === "missed" ? "text-red" : "text-green"
                } text-lg 2xl:text-2xl`}
                title="Chamada recebida"
            />
        );
    };
    const outbound = (item) => {
        return (
            <FontAwesomeIcon
                icon={faLocationArrow}
                className={`${
                    item.status === "missed" ? "text-red" : "text-green"
                } text-lg 2xl:text-2xl`}
                title="Chamada efetuada"
            />
        );
    };

    const contactsData = contacts.getAll();
  
    const rowTransform = (item) => {
        return [
            item.direction === "outbound" ? outbound(item) : inbound(item),
            <span className="font-bold">
                {item.contactName || contactsData?.find(_contact => _contact.phone === Number(item.phone))?.name ||"Desconhecido"}
            </span>,
            item.phone,
            extensions.find((v) => v.id === item.extension)?.name ||
                "Desconhecido",
            queues.find((_queue) => _queue.id === item.queueId)?.name || '',
            formatISODateTime(item.date),
            item.duration,
            item.status === "missed" ? statusMissed : statusAnswered,
            <div className="flex">
                {!item.contactName && (
                    <div
                        className="cursor-pointer text-primary mr-8 text-lg"
                        onClick={() => {
                            addContact &&
                                addContact({
                                    name: item.contactName || "",
                                    phone: item.phone || "",
                                });
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faUserPlus}
                            className="text-lg 2xl:text-2xl"
                        />
                    </div>
                )}
                {item.recorded && (
                    <div
                        className="cursor-pointer text-gray-dark mr-8 text-lg"
                        onClick={() =>
                            downloadRecording(item.callId, item.date)
                        }
                    >
                        <FontAwesomeIcon
                            icon={faVoicemail}
                            className="text-lg 2xl:text-2xl"
                        />
                    </div>
                )}
                <div
                    className="cursor-pointer text-green text-lg"
                    onClick={() => initiateCall(item.phone, item.contactName)}
                >
                    <FontAwesomeIcon
                        icon={faPhone}
                        className="text-lg 2xl:text-2xl"
                    />
                </div>
            </div>,
        ];
    };

    return (
        <TableLayout
            data={data}
            rowTransform={rowTransform}
            emptyMessage={
                hasLoaded
                    ? "Ops! Nenhum dado encontrado"
                    : "Utilize um filtro ou clique em pesquisar"
            }
            headers={headers}
        />
    );
}

export default ReportTable;
