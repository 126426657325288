import { firestore, storage } from "../services/firebase/firebaseInit";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "@firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { functions } from "../services/firebase/firebaseInit";
import { httpsCallable } from "@firebase/functions";
import axios from "axios";

export const getExtensions = async (auth: any) => {
  const companyId = auth.user.token.claims.company;
  const snap = await getDocs(
    collection(firestore, `companies/${companyId}/users`)
  );

  const data = snap.docs.reduce((prev: any, curr: any) => {
    return [
      ...prev,
      {
        value: curr.id,
        name: `${curr.data().name}`,
        extensionNumber: `${curr.data().extensionNumber}`,
        label: `${curr.data().name} | ${curr.data().extensionNumber}`,
      },
    ];
  }, []);

  return data;
};

export const getQueue = async (auth: any, id: any) => {
  const companyId = auth.user.token.claims.company;
  const docRef = doc(firestore, `companies/${companyId}/queues/${id}`);
  const docSnap = await getDoc(docRef);

  const queue = docSnap.data()!;
  return {
    id: docSnap.id,
    name: queue.name,
    extensionNumber: queue.extensionNumber,
    option: queue.option,
    participants: queue.participants,
  };
};

export const getQueues = async (auth: any) => {
  const companyId = auth.user.token.claims.company;

  const snap = await getDocs(
    query(
      collection(firestore, `companies/${companyId}/queues`),
      orderBy("option")
    )
  );

  const data = snap.docs.reduce((prev: any, curr: any) => {
    return [...prev, { ...curr.data(), id: curr.id }];
  }, []);

  return data;
};

export const saveQueues = async (auth: any, data: any) => {
  data.companyId = auth.user.token.claims.company;
  const saveQueues = httpsCallable(functions, "saveQueues");
  const ret = await saveQueues(data);
  return ret.data as { id: string; name: string }[];
};

export const savePredefTTS = async (
  audio: PredefinedAudioMessage,
  reusePreview: any
) => {
  const savePredefTTS = httpsCallable(functions, "savePredefTTS");
  return await savePredefTTS({
    type: audio.id,
    selected: audio.predefined,
    text: audio.text,
    reusePreview,
    suffixOptions: audio.useOptions,
  });
};

export const saveTextTTS = async (
  audio: TextAudioMessage,
  reusePreview: boolean
) => {
  const saveTTS = httpsCallable(functions, "saveTTS");
  return await saveTTS({ type: audio.id, text: audio.text, reusePreview });
};

export const saveNoErrorTTS = async (
  audio: NoErrorMessage,
) => {
  const saveNoErrorTTS = httpsCallable(functions, "saveNoErrorTTS");
  return await saveNoErrorTTS({ type: audio.id })
}

export const generateTempTTS = async (audio: AudioMessage): Promise<string> => {
  if (audio?.type !== "predefined" && audio?.type !== "text")
    throw new Error("Cannot generate temp TTS for an audio file");

  const tempTTS = httpsCallable(functions, "tempTTS");
  const result = await tempTTS({
    type: audio?.type,
    generateOptions: true,
    text: audio?.text,
  });
  if (result) {
    return result.data as string;
  } else {
    throw new Error("");
  }
};

export const uploadMessage = async (
  audio: FileAudioMessage,
  companyId: string
) => {
  if (!audio.formData) return;
  audio.formData.append("companyId", companyId);
  audio.formData.append("type", audio.id);
  audio.formData.append("fileName", audio.filename);

  await axios.post(
    process.env.REACT_APP_FIREBASE_requestUrl + "/uploadMessage",
    audio.formData
  );
};

export const getMessagesMetadata = async (
  companyId: string
): Promise<AudioMessagesMetadata> => {
  const greetingDocRef = doc(firestore, `companies/${companyId}/ivr/greeting`);
  const greetingDocSnap = await getDoc(greetingDocRef);
  const greeting = parseMessageMetadata(
    greetingDocSnap.data() as IvrAudioMessage,
    'greeting'
  );

  const errorDocRef = doc(firestore, `companies/${companyId}/ivr/error`);
  const errorDocSnap = await getDoc(errorDocRef);
  const error = parseMessageMetadata(errorDocSnap.data() as IvrAudioMessage, 'error');

  const awayDocRef = doc(firestore, `companies/${companyId}/ivr/away`);
  const awayDocSnap = await getDoc(awayDocRef);
  const away = parseMessageMetadata(awayDocSnap.data() as IvrAudioMessage, 'away');

  return {
    error,
    greeting,
    away,
  };
};

export const parseMessageMetadata = (
  ivrAudioMessage: IvrAudioMessage, id: string
): AudioMessage => {
  if (!ivrAudioMessage) return;
  switch (ivrAudioMessage.type) {
    case "text":
      return {
        type: "text",
        id,
        text: ivrAudioMessage.text,
      };
    case "predefined":
      return {
        type: "predefined",
        id,
        text: ivrAudioMessage.text,
        predefined: parseInt(ivrAudioMessage.predefined),
      };
    case "file":
      return {
        type: "file",
        id,
        filename: ivrAudioMessage.fileName,
        hasChanged: false,
      };
    case "none":
      return {
        type: "none",
        id,
      };
  }
};

export const getAudio = async (auth: any, type: any) => {
  const companyId = auth.user.token.claims.company;
  const itemRef = ref(storage, `${companyId}/${type}`);
  try {
    const url = await getDownloadURL(itemRef);
    return url;
  } catch (e) {
    return false;
  }
};

export async function getIvrData(companyId: string): Promise<IvrData | null> {
  const docRef = doc(firestore, `companies/${companyId}/ivr/data`);
  const docSnap = await getDoc(docRef);

  const ivr = docSnap.data()!;

  if (ivr) {
    return {
      singleQueueId: ivr.singleQueueId,
      type: ivr.type,
    };
  }

  return null;
}

export async function saveIvrData(data: IvrData) {
  try {
    const fn = httpsCallable(functions, "saveIvrData");
    const result = await fn({ ...data });
    return result.data || false;
  } catch (e) {
    return false;
  }
}

export type IvrData = {
  type: IvrType;
  singleQueueId: string;
};

export type IvrType = "single-queue" | "multi-queue" | "";

export type Queue = {
  id?: string;
  name: string;
  option: number;
  strategy: QueueStrategy;
  participants: Participant[];
};

export type QueueStrategy = "linear" | "rrmemory";

export type Participant = {
  extensionNumber: string;
  id?: string;
  name: string;
};

export type IvrAudioMessage = {
  type: "text" | "predefined" | "file" | "none";
  name: string;
  text: string;
  fileName: string;
  predefined: string;
  formData?: FormData;
  hasChanged?: boolean;
};

export type AudioMessagesMetadata = {
  error: AudioMessage;
  away: AudioMessage;
  greeting: AudioMessage;
};

export type AudioMessage =
  | PredefinedAudioMessage
  | TextAudioMessage
  | FileAudioMessage
  | NoErrorMessage
  | undefined

export type MessageTypes = "predefined" | "text" | "file" | "none";

export type PredefinedAudioMessage = {
  type: "predefined";
  id: string; // is actually the "name" of the audio field
  text: string;
  predefined: number;
  useOptions?: boolean;
};

export type TextAudioMessage = {
  type: "text";
  id: string;
  text: string;
  useOptions?: boolean;
};

export type FileAudioMessage = {
  type: "file";
  id: string;
  filename: string;
  hasChanged: boolean;
  formData?: FormData;
  useOptions?: boolean;
};

export type NoErrorMessage = {
  type: "none";
  id: string;
  useOptions?: boolean;
};