//@ts-ignore
import { AudioMessage } from "../components/audioSelection/audioSelector";
import * as model from "../model/queue.model";

export async function saveQueues(auth: any, queues: model.Queue[]) {
  const ret = await model.saveQueues(auth, queues);
  return ret;
}

export function saveSingleQueueIvr(companyId: string, queueId: string) {
  model.saveIvrData({
    singleQueueId: queueId,
    type: "single-queue",
  });
}

export async function saveMultiQueueIvr(
  companyId: string,
  greeting: AudioMessage,
  error: AudioMessage
) {
  saveIvrAudio(companyId, greeting, false);
  saveIvrAudio(companyId, error, false);
  model.saveIvrData({
    singleQueueId: "",
    type: "multi-queue",
  });
}

export async function getIvrData(companyId: string) {
  return await model.getIvrData(companyId);
}

export async function saveIvrAudio(
  companyId: string,
  audio: AudioMessage,
  reusePreview: boolean
) {
  switch (audio?.type) {
    case "text":
      await model.saveTextTTS(audio, reusePreview);
      break;
    case "predefined":
      await model.savePredefTTS(audio, reusePreview);
      break;
    case "file":
      if (audio.hasChanged) {
        await model.uploadMessage(audio, companyId);
      }
      break;
    case "none":
      await model.saveNoErrorTTS(audio)
      break;
  }
}
